import React, {useState } from 'react';
import Paper from '@material-ui/core/Paper';

import { Box, Button} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import EnhancedTable from '../../Shared/EnhancedTable/EnhancedTable';
import { tableStyles } from './../CaseDashboard.style';
import RecordsDocumentsDialog from './RecordsDocumentsDialog/RecordsDocumentsDialog';
import RecordChronologyMenu from './RecordChronolgyMenu/RecordChronologyMenu';

const headCells = [
  { id: 'locationName', label: 'Location Name', width: '20%' },
  { id: 'locationAddress', label: 'Address', width: '20%' },
  { id: 'recordType', label: 'Record Type', width: '12%' },
  {
    id: 'expirationDate',
    label: 'Expiration Date',
    width: '12%',
    isDate: true
  },
  { id: 'records', label: 'Records', width: '12%' }
];

const CaseRecords = (props) => {
  const {
    classes,
    expertRecordsList = [],
    caseNo,
    isLoading,
    recordsOf,
    canDownloadFiles,
    chronFileRequestId
  } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [recordInfo, setRecordInfo] = useState(false);
  const [chronologyFileUploaded, setChronologyFileUploaded] = useState(false);
  const [chronologyFileData, setChronologyFileData] = useState(null);

  // action buttons
  function ViewButton(props) {
    const { classes, row } = props;

   const handleButtonClick = ()=>{ 
     const recordInfo = {
       trackingNo : row['trackingNo'],
       caseNo: caseNo,
       headerData:[{value: recordsOf, label:"Record Of"},{value:row['locationName'],label:"Location Name"},{value:row['recordType'], label:"Record Type"}],
      }
       
       setRecordInfo(recordInfo);
       setOpenDialog(true);
  };
  
    return (
      <div className={classes.viewSection}>
        <Button
          data-testid="caseRecordOpenButton"
          variant="contained"
          size="small"
          className={classes.viewBtn}
          onClick={handleButtonClick}>
          Open
        </Button>
      </div>
    );
  }

  const customCells = {
    records: <ViewButton classes={classes} />
  };

  return (
    <div>
      <Paper elevation={0} className={classes.tableTitle}>
      <Box display={'flex'} justifyContent={"space-between"}>
        Records
      {chronFileRequestId && 
        <RecordChronologyMenu chronFileRequestId = {chronFileRequestId} 
        setChronologyFileUploaded = {setChronologyFileUploaded}
        setChronologyFileData = {setChronologyFileData}
        chronologyFileData={ chronologyFileData}
        chronologyFileUploaded={chronologyFileUploaded}
        canDownloadFiles = {canDownloadFiles}
        />
      }
      </Box>
      </Paper>
      <EnhancedTable
        rows={expertRecordsList}
        headCells={headCells}
        customCells={customCells}
        rowsPerPageOptions={[]}
        showPagination
        pageCount={5}
        isLoading={isLoading}
      />
  
      <RecordsDocumentsDialog 
     	      title="Shared Records"
            id="record-documents-dialog"
            canDownloadFiles= {canDownloadFiles}
            open= {openDialog}
            setOpen={setOpenDialog}
            recordsInfo = {recordInfo}/>
    </div>
  );
};

export default withStyles(tableStyles)(CaseRecords);
